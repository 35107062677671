const { Provider } = require('./src/state');
const React = require("react")
const Layout = require('./src/layouts/index').default;

exports.disableCorePrefetching = () => true

exports.wrapRootElement = ({ element }) => {
  return (
    <Provider>
      {element}
    </Provider>
  )
}

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}