import { Reducer, useEffect } from 'react';

import produce from 'immer';
import { createContainer } from 'react-tracked';
import { useReducerAsync } from 'use-reducer-async';
import { asyncActionHandlers, validateToken } from './actions';

const windowGlobal = typeof window !== 'undefined' && window;

const LOCAL_STORAGE_KEY = 'storage';

export enum ActionTypes {
    MAP_POSITION_CHANGE = "MAP_POSITION_CHANGE",
    SET_LOCATION = "SET_LOCATION",
    SET_POWERPLANT1PRODUCTION = "SET_POWERPLANT1PRODUCTION",
    SET_POWERPLANT2PRODUCTION = "SET_POWERPLANT2PRODUCTION",
}

export type Action =
    | { type: ActionTypes.SET_POWERPLANT1PRODUCTION, production: number }
    | { type: ActionTypes.SET_POWERPLANT2PRODUCTION, production: number }
    | { type: ActionTypes.SET_LOCATION, location: string };

export type AsyncAction =
    | { type: ActionTypes.SET_LOCATION, location: string };


export interface IHydropowerPlant {
    id: string;
    name: string;
    latitude: number;
    longitude: number;
}

export interface IPersistedState {
    token?: string;
}

export interface IMapViewState {
    latitude: number;
    longitude: number;
    zoom: number;
    pitch: number;
    bearing: number;
}

export interface IState {
    mapViewState: IMapViewState;
    location: string;
    persisted: IPersistedState;
    hydropowerplants: IHydropowerPlant[];
    plant1Production: number;
    plant2Production: number;
}

const initialState: IState = {
    location: null,
    mapViewState: { // 59.773799, 9.908798
        latitude: 59.73471855086528,
        longitude: 10.07292346210886,
        zoom: 10.010444561413199,
        pitch: 60,
        bearing: -21.73913043478262
    },
    persisted: {
    },
    hydropowerplants: [
        { id: "1", name: "Kraftverk #1", latitude: 59.842243, longitude: 9.890109 },
        { id: "2", name: "Kraftverk #2", latitude: 59.733714, longitude: 9.872098 }
    ],
    plant1Production: 0,
    plant2Production: 0,
};

const reducer: Reducer<IState, Action> = (state, action) => {
    const nextState = produce(state, (draft) => {

        switch (action.type) {
            case ActionTypes.SET_LOCATION:
                draft.location = action.location;
                break;
            case ActionTypes.SET_POWERPLANT1PRODUCTION:
                draft.plant1Production = action.production;
                break;
            case ActionTypes.SET_POWERPLANT2PRODUCTION:
                draft.plant2Production = action.production;
                break;
        }
    });
    return nextState;
};

const useValue = () => {

    const [state, useDispatch] = useReducerAsync<Reducer<IState, Action>, AsyncAction>(reducer, initialState, asyncActionHandlers);

    const init = () => {
        const _state: IState = initialState;
        let _persisted: IPersistedState;
        try {
            _persisted = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
            if (_persisted) {
                _state.persisted = _persisted;
            }
            // validate preloadedState if necessary
        } catch (e) {
            // ignore
        }
        return _state || initialState;
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (windowGlobal) {
            if (!state.persisted) { state.persisted = initialState.persisted; }
            windowGlobal.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.persisted));
        }
    }, [state.persisted]);

    return [state, useDispatch] as const;
};

// @ts-ignore
export const { Provider, useTracked, useTrackedState, useUpdate: useDispatch } = createContainer(useValue);